<template>
	<div class="mdc-snackbar">
		<div class="mdc-snackbar__surface">
			<div class="mdc-snackbar__label"
				role="status"
				aria-live="polite">
				{{options.message}}
			</div>
			<div class="mdc-snackbar__actions">
				<button type="button" class="mdc-button mdc-snackbar__action" @click="options.action()" v-if="options.action && options.actionLabel">{{options.actionLabel}}</button>
				<button class="mdc-icon-button mdc-snackbar__dismiss material-icons" title="Dismiss">close</button>
			</div>
		</div>
	</div>
</template>

<script>
// Dependencies
import { MDCSnackbar } from '@material/snackbar'

// Component
export default {
	name: 'noticePlugin',
	data: () => ({
		snackbar: null,
		defaults: {
			actionLabel: '',
			action: () => ({}),
			timeout: 5000,
		},
		options: {},
		queued: [],
		timer: null,
	}),
	mounted() {
		this.snackbar = new MDCSnackbar(this.$el)
		this.snackbar.listen(`MDCSnackbar:closed`, () => {
			this.queued.shift()
		})
	},
	methods: {
		close() {
			this.snackbar.close()
		},
	},
	watch: {
		queued(opt) {
			if (!opt.length) return

			const options = this.queued[0]
			this.options = Object.assign({}, this.defaults, options)
			this.snackbar.open()
		},
	},
}
</script>

<style scoped lang="scss">
@import '@material/snackbar/mdc-snackbar';
@import '@material/button/mdc-button';
@import '@material/icon-button/mdc-icon-button';

.mdc-button {
	@include mdc-button-ink-color(color_('text', 'base'));
}
</style>
