<template>
	<div class="content-block grid grid--align-top" :class="{'content-block--no-margin':!margin}">
		<div class="grid__inner">
			<div class="grid__cell grid__cell--span-12">
				<h4 class="content-block__header">
					<slot name="header"></slot>
				</h4>
			</div>
			<div class="grid__cell grid__cell--span-12">
				<div class="lines lines--horizontal"></div>
			</div>
			<div class="grid__cell grid__cell--span-12">
				<h5 class="content-block__subheader">
					<slot name="subheader"></slot>
				</h5>
			</div>
			<div class="grid__cell grid__cell--span-12">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'contentBlock',
	props: {
		margin: {
			type: Boolean,
			default: true,
		},
	},
}
</script>

<style scoped lang="scss">
.content-block {
	@include font-size('h6');
	@include gutter('margin-bottom', 4);
	@include gutter('margin-top', 4);
	line-height: 2;
	text-align: center;

	@include media-query('phone') {
		@include gutter('margin-bottom', 2);
		@include gutter('margin-top', 2);
	}

	&--no-margin {
		@include gutter('margin-bottom', 1);
		@include gutter('margin-top', 0);
	}

	&__header {
		@include font-size('h1');
		@include gutter('margin-bottom', 0.5);
		@include gutter('margin-top', 0);
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		white-space: pre;

		@include media-query('tablet') {
			white-space: inherit;
		}

		@include media-query('phone') {
			@include font-size('h2');
			white-space: inherit;
		}
	}

	&__subheader {
		@include gutter('margin-bottom', 1);
		@include gutter('margin-top', 0.5);
		@include font-size('h4');
		line-height: 1.3;
		text-align: center;
		text-transform: uppercase;
		// white-space: pre;

		@include media-query('tablet') {
			white-space: inherit;
		}

		@include media-query('phone') {
			white-space: inherit;
		}
	}

	&__links {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-query('phone') {
			flex-direction: column;
		}
	}

	&__link {
		@include gutter('padding-top', 0.5);
		@include gutter('padding-bottom', 0.5);
		@include gutter('padding-right', 1);
		@include gutter('padding-left', 1);
		@include gutter('margin', 0.5);
		border: 3px solid color_('primary');
		color: color_('primary');
		font-family: $font-family-secondary;
		text-transform: uppercase;
		transition: all 200ms $ease-standard;

		&:hover {
			background-color: color_('primary');
			color: color_('text', 'dark');
		}
	}

	.lines {
		&--horizontal {
			background-image: url('../assets/dashed-lines--horizontal@2x.png');
			background-repeat: repeat-x;
			background-size: contain;
			height: 7px;
		}
	}
}
</style>
