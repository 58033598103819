<template>
	<div
		class="mdc-dialog"
		role="dialog"
		aria-modal="true"
		aria-labelledby="dialog_title"
	>
		<div class="mdc-dialog__container">
			<div class="mdc-dialog__surface">
				<h2 class="mdc-dialog__title" id="dialog_title"><slot name="title"></slot></h2>
				<div class="mdc-dialog__content" id="dialog_content">
					<slot></slot>
				</div>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>
// Dependencies
import { MDCDialog } from '@material/dialog'
import { Bus } from '@/lib/bus'

// Component
export default {
	name: 'modal',
	props: {
		open: Boolean,
	},
	data: () => ({
		dialog: null,
		events: ['opening', 'closing', 'opened', 'closed'],
	}),
	mounted() {
		this.dialog = new MDCDialog(this.$el)
		this.events.forEach(evt => {
			this.dialog.listen(`MDCDialog:${evt}`, () => Bus.$emit(`MDCDialog:${evt}`))
		})
		if (this.open) {
			this.dialog.open()
		}
	},
	methods: {
		closeHandler() {
			this.dialog.close()
		},
	},
	watch: {
		open(val) {
			if (val) {
				this.dialog.open()
			} else {
				this.dialog.close()
			}
		},
	},
}
</script>

<style scoped lang="scss">
@import '@material/dialog/mdc-dialog';

.mdc-dialog {

	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 50;

	&__title {
		background-color: color_('primary');
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		color: color_('white') !important;
		font-family: $font-family-secondary;
		text-align: center;
	}

	&__surface {
		// width: 100%;
		@include media-query('desktop') {
			max-width: map-get($mdc-layout-grid-column-width, 'desktop') * 8 !important;
		}
		@include media-query('tablet') {
			max-width: map-get($mdc-layout-grid-column-width, 'tablet') * 8 !important;
		}
	}

	&__content {
		padding: 2rem !important;
	}
}
</style>
