<template>
	<div class="shark" data-0="transform: translate3d(0px, -310px, 0px);" data-1000="transform: translate3d(0px, 200px, 0px);">
		<div class="shark__part shark__part--body"></div>
		<div
			class="shark__part shark__part--fin-1"
			data-center-center="transform:translate3d(0px, 0px, 0px) rotate(0deg); opacity: 1;"
			data-top="transform:translate3d(300px, 150px, 0px) rotate(45deg); opacity: 0;"
		></div>
		<div
			class="shark__part shark__part--fin-2"
			data-center-center="transform:translate3d(0px, 0px, 0px) rotate(0deg); opacity: 1;"
			data-top="transform:translate3d(-300px, -150px, 0px) rotate(-45deg); opacity: 0;"
		></div>
		<div
			class="shark__part shark__part--fin-3"
			data-center-center="transform:translate3d(0px, 0px, 0px) rotate(0deg); opacity: 1;"
			data-top="transform:translate3d(300px, 250px, 0px) rotate(25deg); opacity: 0;"
		></div>
		<div
			class="shark__part shark__part--fin-4"
			data-center-center="transform:translate3d(0px, 0px, 0px) rotate(0deg); opacity: 1;"
			data-top="transform:translate3d(200px, 500px, 0px) rotate(-35deg); opacity: 0;"
		></div>
		<div
			class="shark__part shark__part--fin-5"
			data-center-center="transform:translate3d(0px, 0px, 0px) rotate(0deg); opacity: 1;"
			data-top="transform:translate3d(-600px, 450px, 0px) rotate(-85deg); opacity: 0;"
		></div>
		<div
			class="shark__part shark__part--fin-6"
			data-center-center="transform:translate3d(0px, 0px, 0px) rotate(0deg); opacity: 1;"
			data-top="transform:translate3d(500px, 250px, 0px) rotate(45deg); opacity: 0;"
		></div>
		<div
			class="shark__part shark__part--cuts"
			data-200-bottom="opacity: 0;"
			data-top="opacity: 1;"
		></div>
	</div>
</template>

<script>
export default {
	name: 'shark',
}
</script>

<style scoped lang="scss">
@function sized($size, $screen: desktop) {
	$sized: null;

	@if ($screen == desktop) {
		$sized: $size * 16px;
	}
	@if ($screen == tablet) {
		$sized: $size * 14px;
	}
	@if ($screen == phone) {
		$sized: $size * 9px;
	}
	@return $sized;
}

.shark {
	max-width: 100%;
	position: relative;
	z-index: 8;

	@each $base in map-keys($mdc-layout-grid-columns) {
		@include media-query($base) {
			height: sized(57.9375, $base);
			width: sized(33.25, $base);
		}
	}

	&__part {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		position: absolute;

		&--body {
			background-image: url('../assets/shark__body.png');

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(57.9375, $base);
					width: sized(24.8375, $base);
				}
			}
		}

		&--fin-1 {
			background-image: url('../assets/shark__fin-1.png');
			// height: $font-size-base * 9;
			// width: $font-size-base * 7.9375;
			// left: $font-size-base * 25.375; // 406px;
			// top: $font-size-base * 10.9375; // 175px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(9, $base);
					width: sized(7.9375, $base);
					left: sized(25.375, $base);
					top: sized(10.9375, $base);
				}
			}
		}

		&--fin-2 {
			background-image: url('../assets/shark__fin-2.png');
			// height: $font-size-base * 5.5625; //89px;
			// width: $font-size-base * 2.625; //42px;
			// left: $font-size-base * 11.0625; //177px;
			// top: $font-size-base * 22.4; //336px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(5.5625, $base);
					width: sized(2.625, $base);
					left: sized(11.0625, $base);
					top: sized(21, $base);
				}
			}
		}

		&--fin-3 {
			background-image: url('../assets/shark__fin-3.png');
			// width: 40px;
			// height: 65px;
			// left: 401px;
			// top: 360px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(4.0625, $base);
					width: sized(2.5, $base);
					left: sized(25.0625, $base);
					top: sized(22.5, $base);
				}
			}
		}

		&--fin-4 {
			background-image: url('../assets/shark__fin-4.png');
			// width: 33px;
			// height: 83px;
			// left: 402px;
			// top: 457px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(5.1875, $base);
					width: sized(2.0625, $base);
					left: sized(25.125, $base);
					top: sized(28.5625, $base);
				}
			}
		}


		&--fin-5 {
			background-image: url('../assets/shark__fin-5.png');
			// height: 134px;
			// width: 108px;
			// left: 68px;
			// top: 510px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(8.375, $base);
					width: sized(6.75, $base);
					left: sized(4.25, $base);
					top: sized(31.875, $base);
				}
			}
		}

		&--fin-6 {
			background-image: url('../assets/shark__fin-6.png');
			// height: 90px;
			// width: 129px;
			// left: 396px;
			// top: 634px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(5.625, $base);
					width: sized(8.0625, $base);
					left: sized(24.75, $base);
					top: sized(39.625, $base);
				}
			}
		}

		&--cuts {
			background-image: url('../assets/shark__cuts.png');
			// height: 533px;
			// width: 240px;
			// left: 171px;
			// top: 202px;

			@each $base in map-keys($mdc-layout-grid-columns) {
				@include media-query($base) {
					height: sized(33.3125, $base);
					width: sized(15, $base);
					left: sized(10.6875, $base);
					top: sized(12.625, $base);
				}
			}
		}
	}
}
</style>
