<template>
	<div class="hero">
		<div class="hero__layer hero__layer-sky"></div>
		<div
			class="hero__layer hero__layer-sun"
			data-0="transform: translateY(0px);"
			data-200="transform: translateY(200px);"
		></div>
		<div class="hero__layer hero__layer-clouds"></div>
		<div
			class="hero__layer hero__layer-buildings-3"
			data-0="transform: translateY(0px)"
			data-200="transform: translateY(120px)"
		></div>
		<div
			class="hero__layer hero__layer-buildings-2"
			data-0="transform: translateY(0px)"
			data-200="transform: translateY(115px)"
		></div>
		<div
			class="hero__layer hero__layer-buildings-1"
			data-0="transform: translateY(0px)"
			data-200="transform: translateY(110px)"
		></div>
		<div
			class="hero__layer hero__layer-trees"
			data-0="transform: translateY(0px)"
			data-200="transform: translateY(90px)"
		></div>
		<div
			class="hero__layer hero__layer-ocean-3"
			data-0="transform: translateY(0px)"
			data-200="transform: translateY(70px)"
		></div>
		<div
			class="hero__layer hero__layer-ocean-2"
			data-0="transform: translateY(0px)"
			data-200="transform: translateY(40px)"
		></div>
		<div
			class="hero__layer hero__layer-ocean-1"
		></div>
		<!-- <div
			class="hero__layer hero__layer-ocean-1--bottom"
		></div> -->
	</div>
</template>

<script>
// Dependencies

// Component
export default {
	name: 'hero',
}
</script>

<style scoped lang="scss">
.hero {
	background-color: #faf2e5;
	background-image: url('../assets/bg__header.png');
	background-position: bottom center;
	background-repeat: no-repeat;
	// background-size: contain;
	height: 56.25vw;
	max-height: 550px;
	overflow: hidden;
	position: relative;

	&__layer {
		left: 0;
		position: absolute;
		// top: 0;
		width: 100%;
		height: 100%;

		&-sky {
			background-image: url('../assets/bg__header-sky.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			// height: 568px;
			top: 0;
			z-index: 1;
		}

		&-clouds {
			background-image: url('../assets/bg__header-clouds.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: auto;
			// height: 330px;
			// top: 150px;
			z-index: 3;
		}

		&-sun {
			background-image: url('../assets/bg__header-sun.png');
			background-position: center top;
			background-repeat: no-repeat;
			background-size: auto;
			// height: 119px;
			top: 130px;
			z-index: 2;
		}

		&-buildings-1 {
			background-image: url('../assets/bg__header-buildings-1.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 472px;
			bottom: 0;
			z-index: 6;
			// transform: translateY(10%);
		}

		&-buildings-2 {
			background-image: url('../assets/bg__header-buildings-2.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 433px;
			bottom: 0;
			z-index: 5;
			// transform: translateY(20%);
		}

		&-buildings-3 {
			background-image: url('../assets/bg__header-buildings-3.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 420px;
			bottom: 0;
			z-index: 4;
			// transform: translateY(20%);
		}

		&-trees {
			background-image: url('../assets/bg__header-trees.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 314px;
			bottom: 0;
			z-index: 7;
			// transform: translateY(15%);
		}

		&-ocean-1 {
			background-image: url('../assets/bg__header-ocean-1.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 404px;
			bottom: 0;
			z-index: 10;
			// transform: translateY(50%);
		}

		&-ocean-2 {
			background-image: url('../assets/bg__header-ocean-2.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 226px;
			bottom: 0;
			z-index: 9;
			// transform: translateY(25%);
		}

		&-ocean-3 {
			background-image: url('../assets/bg__header-ocean-3.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			// height: 376px;
			bottom: 0;
			z-index: 8;
			// transform: translateY(20%);
		}

		&-ocean-1--bottom {
			background-image: url('../assets/bg__header-ocean-1--bottom.png');
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: 100% auto;
			height: 326px;
			// height: 404px;
			bottom: -326px;
			z-index: 10;
			// transform: translateY(90%);
		}
	}
}

</style>
