<template>
	<div id="app">
		<site-header></site-header>

		<hero></hero>
		<div id="content" data-0="overflow: visible;" data-400="overflow: hidden;">

		<content-top></content-top>

		<content-block :margin="false">
			<template v-slot:header>I thought this
was illegal.</template>
			<template v-slot:subheader>The law only prohibits shark ”finning” — the act of cutting off fins
at sea and throwing the still-alive shark overboard.</template>
			But in Florida it’s perfectly legal to possess and sell an already detached fin. More than seventy percent of them wind up as shark fin soup, a delicacy in some cultures. Or traders offload the meat as a low-grade filler ingredient for products such as pet food.
		</content-block>

		<pull-quote idx="a">
			<template v-slot:left>
				<sticker :rotate="20" :position="{left: '20px', top: '-130px', zIndex:4}">Oceanic Ecosystems Depend On Sharks</sticker>
				<img src="@/assets/pull-quote__1-left.png">
			</template>
			<template v-slot:center>
				<img src="@/assets/pull-quote__1-center.png">
			</template>
			<template v-slot:right>
				<img src="@/assets/pull-quote__1-right.png">
				<sticker :rotate="-22" :position="{right: '120px', bottom: '-8%'}">1/3 of All Shark Species Are Threatened With Extinction</sticker>
			</template>
		</pull-quote>

		<content-block>
			<template v-slot:header>Florida,
Really?!?!</template>
			<template v-slot:subheader>Yes, shark fin trading is legal in florida. that’s why the
sunshine state traffics more fins than any other port in the u.s.</template>
			When twelve other coastal states banned the sale and trade of shark fins, Florida became the primary hub for imports/exports in the nation. Poachers know how difficult it is for regulators to catch them actually clipping fins while out at sea, and since many fin shipments come in from countries where finning is legal, it’s impossible to trace a cargo once it lands in port. With such low risks and high market prices, the shark fin business in Florida is booming. Only a complete prohibition of selling and trading of fins will end this crisis.
		</content-block>

		<content-block>
			<template v-slot:header>I don’t like sharks.
Less is better.</template>
			<template v-slot:subheader>You’re afraid of sharks. We get it. But do you like dolphins?
How about fresh seafood, clean water, and pristine beaches?</template>
			As apex predators, sharks play a vital role in the ecosystem by regulating fish populations, ensuring a diversity of species, and serving as a leading-indicator of ocean health. Currently, one-third of all shark species are threatened with extinction. And without sharks, we won’t have a thriving ocean. Love them or hate them — we need them.
		</content-block>

		<content-block>
			<template v-slot:header>I am pro-business.
Will this hurt our economy and jobs?</template>
			<template v-slot:subheader>Ending the shark fin trade won't negatively affect recreational OR most commercial fishing
IN FLORIDA. FISHERMEN WILL STILL BE ABLE TO catch sharks. JUST NOT sell the fins.</template>
			The positive impact on Florida’s economy will more than offset the slight loss of revenue. Dive tourism and shark watching alone —  where abundant reef and marine life are the main attraction — generate far more income than the entire shark fin trade nationwide. But it goes beyond that. In Florida, our greatest asset is our natural habitat. If the fin trade continues, many shark species will disappear and the ocean we rely on for tourism will suffer. Protecting sharks is not only the right thing to do, it is also an important part of preserving Florida’s economic future.
		</content-block>

		<pull-quote idx="b" :adjust="{top: -100, center: 0}">
			<template v-slot:header>
				<img src="@/assets/pull-quote__2-header.png" alt="U.S. Shark Fin Export: Under $1 Million">
			</template>
			<template v-slot:left>
				<img src="@/assets/pull-quote__2-left.png">
			</template>
			<template v-slot:center>
				<img src="@/assets/pull-quote__2-center.png">
			</template>
			<template v-slot:right>
				<img src="@/assets/pull-quote__2-right.png">
				<sticker :rotate="20" :position="{right: '100px', bottom: '-140px'}">Florida is the Shark Fin Trade Capital of the U.S.</sticker>
			</template>
		</pull-quote>

		<content-block>
			<template v-slot:header>Is this a Republican
or Democratic proposal?</template>
			<template v-slot:subheader>It's a bi-partisan legislative effort — supported EQUALLY BY Republicans and Democrats.</template>

		</content-block>

		<help></help>

		</div>

		<site-footer></site-footer>
		<modal :open="modalOpen">
			<template v-slot:title>SHARE #NoFinFL</template>
			<share-links></share-links>
		</modal>
	</div>
</template>

<script>
// Dependencies
import skrollr from 'skrollr'
import SiteHeader from '@/components/SiteHeader.vue'
import Hero from '@/components/Hero.vue'
import ContentTop from '@/components/ContentTop.vue'
import ContentBlock from '@/components/ContentBlock.vue'
import Sticker from '@/components/Sticker.vue'
import PullQuote from '@/components/PullQuote.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import Help from '@/components/Help.vue'
import Modal from '@/components/Modal.vue'
import ShareLinks from '@/components/ShareLinks.vue'
import { MDCSnackbar } from '@material/snackbar'
import { Bus } from '@/lib/bus'

// Component
export default {
	name: 'app',
	components: {
		SiteHeader,
		Hero,
		ContentTop,
		ContentBlock,
		Sticker,
		PullQuote,
		Help,
		SiteFooter,
		Modal,
		ShareLinks,
	},
	data: () => ({
		s: null,
		modalOpen: false,
	}),
	created() {
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	mounted() {
		this.$nextTick(() => {
			setTimeout(() => {
				this.s = skrollr.init()
				if (this.s.isMobile()) {
					this.s.destroy()
				}
			}, 1000)
		})

		Bus.$on('modalOpen', s => {
			this.modalOpen = s
		})

		Bus.$on('MDCDialog:closed', () => {
			this.modalOpen = false
		})
	},
	methods: {
		showRefresh(evt) {
			this.$notice('New version available! Please refresh.', () => {
				evt.detail.postMessage({action: 'skipWaiting'})
			}, {
				actionLabel: 'REFRESH',
				timeout: 10000,
			})
		},
	},
}
</script>

<style lang="scss">
@import './styles/partials.scss';

#app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	// text-align: center;
	// color: #2c3e50;
	// margin-top: 60px;

}

#content {
	background : -moz-linear-gradient(50% 86.65% 90deg,rgba(14, 32, 49, 1) 0%,rgba(14, 37, 53, 1) 7.67%,rgba(14, 52, 65, 1) 16.87%,rgba(15, 76, 84, 1) 26.85%,rgba(16, 110, 111, 1) 37.39%,rgba(17, 153, 145, 1) 48.27%,rgba(17, 168, 157, 1) 51.71%,rgba(248, 162, 134, 1) 100%,rgba(248, 162, 134, 1) 100%);
	background : -webkit-linear-gradient(90deg, rgba(14, 32, 49, 1) 0%, rgba(14, 37, 53, 1) 7.67%, rgba(14, 52, 65, 1) 16.87%, rgba(15, 76, 84, 1) 26.85%, rgba(16, 110, 111, 1) 37.39%, rgba(17, 153, 145, 1) 48.27%, rgba(17, 168, 157, 1) 51.71%, rgba(248, 162, 134, 1) 100%, rgba(248, 162, 134, 1) 100%);
	background : -webkit-gradient(linear,50% 86.65% ,50% -6.62% ,color-stop(0,rgba(14, 32, 49, 1) ),color-stop(0.0767,rgba(14, 37, 53, 1) ),color-stop(0.1687,rgba(14, 52, 65, 1) ),color-stop(0.2685,rgba(15, 76, 84, 1) ),color-stop(0.3739,rgba(16, 110, 111, 1) ),color-stop(0.4827,rgba(17, 153, 145, 1) ),color-stop(0.5171,rgba(17, 168, 157, 1) ),color-stop(1,rgba(248, 162, 134, 1) ),color-stop(1,rgba(248, 162, 134, 1) ));
	background : -o-linear-gradient(90deg, rgba(14, 32, 49, 1) 0%, rgba(14, 37, 53, 1) 7.67%, rgba(14, 52, 65, 1) 16.87%, rgba(15, 76, 84, 1) 26.85%, rgba(16, 110, 111, 1) 37.39%, rgba(17, 153, 145, 1) 48.27%, rgba(17, 168, 157, 1) 51.71%, rgba(248, 162, 134, 1) 100%, rgba(248, 162, 134, 1) 100%);
	background : -ms-linear-gradient(90deg, rgba(14, 32, 49, 1) 0%, rgba(14, 37, 53, 1) 7.67%, rgba(14, 52, 65, 1) 16.87%, rgba(15, 76, 84, 1) 26.85%, rgba(16, 110, 111, 1) 37.39%, rgba(17, 153, 145, 1) 48.27%, rgba(17, 168, 157, 1) 51.71%, rgba(248, 162, 134, 1) 100%, rgba(248, 162, 134, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#0E2031', endColorstr='#F8A286' ,GradientType=0)";
	background : linear-gradient(0deg, rgba(14, 32, 49, 1) 0%, rgba(14, 37, 53, 1) 7.67%, rgba(14, 52, 65, 1) 16.87%, rgba(15, 76, 84, 1) 26.85%, rgba(16, 110, 111, 1) 37.39%, rgba(17, 153, 145, 1) 48.27%, rgba(17, 168, 157, 1) 50%, rgba(248, 162, 134, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F8A286',endColorstr='#0E2031' , GradientType=0);

	display: flex;
	flex-direction: column;
}
</style>
