<template>
	<div class="pull-quote">
		<div class="pull-quote__header">
			<slot name="header"></slot>
		</div>
		<div class="pull-quote__content">
			<div class="pull-quote__copy pull-quote__copy--left">
				<slot name="left"></slot>
			</div>
			<div
				:id="`pq-${idx}-a`"
				class="pull-quote__copy pull-quote__copy--center"
				v-bind="optionsA"
			>
				<slot name="center"></slot>
			</div>
			<div
				:id="`pq-${idx}-b`"
				class="pull-quote__copy pull-quote__copy--right"
				v-bind="optionsB"
			>
				<slot name="right"></slot>
			</div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'pullQuote',
	props: {
		idx: {
			type: String,
			default: 'a',
		},
		adjust: {
			type: Object,
			default: () => ({
				top: 0,
				center: 0,
			}),
		},
	},
	computed: {
		optionsA() {
			return {
				[`data-${this.adjust.top}-bottom-top`]: "transform: translateY(15%);",
				[`data-${this.adjust.center}-center-center`]: "transform: translateY(0%);",
			}
		},
		optionsB() {
			return {
				[`data-${this.adjust.top}-bottom-top`]: "transform: translateY(30%);",
				[`data-${this.adjust.center}-center-center`]: "transform: translateY(0%);",
			}
		},
	},
}
</script>

<style scoped lang="scss">
.pull-quote {
	@include gutter('margin-bottom', 2);
	@include gutter('margin-top', 1);
	@include gutter('padding-bottom', 5);
	@include gutter('padding-top', 5);
	position: relative;

	@include media-query('phone') {
		@include gutter('padding-bottom', 2);
		@include gutter('padding-left', 1);
		@include gutter('padding-right', 1);
		@include gutter('padding-top', 2);
	}

	&__header {
		text-align: center;
		img {
			margin: 0 auto;
			max-width: 100%;
		}
	}

	&__content {
		justify-content: center;
		display: flex;
		flex-direction: row;
		position: relative;
	}

	&__copy {
		img {
			position: relative;
			z-index: 15;

			@include media-query('phone') {
				max-width: 100%;
			}
		}

		top: 0;

		&--left {
			position: relative;
		}
		&--center {
			position: absolute;
		}
		&--right {
			position: absolute;
		}
	}
}
</style>
