<template>
	<div class="content-top">
		<div class="content-top__ocean"></div>
		<h4 class="content-top__subheader">Sharks are killed each year for their fins.</h4>
		<div class="grid">
			<div class="grid__inner">
				<div class="grid__cell grid__cell--span-4 content-top__leads grid__cell--order-2-phone">
					<h5 class="content-top__leads-header">Florida Leads U.S. in Fin Trading</h5>
				</div>
				<div class="grid__cell grid__cell--span-8 content-top__shark grid__cell--order-1-phone">
					<shark></shark>
				</div>
				<div class="grid__cell grid__cell--span-12 content-top__beginning grid__cell--order-3-phone">
					<h4 class="content-top__beginning-header">But That's Just The Beginning.</h4>
					Take a deeper dive into ways to help end the shark fin trade in Florida.
				</div>
				<div class="grid__cell grid__cell--span-12 grid__cell--align-center grid__cell--order-4-phone">
					<div class="lines lines--vertical"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Dependencies
import Shark from '@/components/Shark.vue'

// Component
export default {
	name: 'contentTop',
	components: {
		Shark,
	},
}
</script>

<style scoped lang="scss">
.content-top {

	position: relative;

	&__ocean {
		background-image: url('../assets/bg__header-ocean-1--bottom.png');
		background-position: center top;
		background-repeat: no-repeat;
		background-size: 100% auto;
		position: absolute;
		height: 326px;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 9;
	}

	&__subheader {
		@include font-size('h4');
		text-align: center;
		text-transform: uppercase;
		position: relative;
		z-index: 9;
	}

	&__leads {
		@include gutter('padding-bottom', 16);
		@include gutter('padding-top', 16);
		text-align: center;

		&-header {
			font-size: $font-size-base * 5.125;
			line-height: 1;
			text-transform: uppercase;

			@include media-query('phone') {
				@include gutter('margin-top', 1);
			}
		}

		@include media-query('phone') {
			@include gutter('padding-bottom', 2);
			@include gutter('padding-top', 1);
		}
	}

	&__beginning {
		@include font-size('h6');
		text-align: center;

		&-header {
			@include font-size('h4');
			@include gutter('margin-bottom', 0);
			text-transform: uppercase;
		}
	}

	.lines {
		&--vertical {
			background-image: url('../assets/dashed-lines--vertical@2x.png');
			background-repeat: repeat-y;
			background-size: contain;
			height: 262px;
			width: 7px;
			margin: 0 auto;
		}
	}
}
</style>
