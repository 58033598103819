<template>
	<header class="site-header grid grid--align-center">
		<div class="grid__inner">
			<div class="grid__cell grid__cell--span-3-desktop grid__cell--span-3-tablet grid__cell--span-1-phone">
				<img src="@/assets/logo__shark-allies.svg" class="site-header__logo">
			</div>
			<div class="grid__cell grid__cell--span-9-desktop grid__cell--span-5-tablet grid__cell--span-3-phone">
				<nav class="site-header__nav">
					<div
						class="site-header__nav-item"
						v-for="(item, key) in navItems"
						:class="item.class"
						:key="key"
					>
						<a :href="item.link">{{item.label}}</a>
					</div>
					<div
						class="site-header__nav-item outline"
					>
						<a href="#" @click.prevent="bus.$emit('modalOpen', true)">Share</a>
					</div>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>
// Dependencies
// import Modal from '@/components/Modal.vue'
import { Bus } from '@/lib/bus'

export default {
	name: 'siteHeader',
	components: {
		// Modal,
	},
	data: () => ({
		modalOpen: false,
		bus: Bus,
		navItems: [
			{
				label: 'Store',
				link: 'https://represent.com/store/nofinfl',
			},
			{
				label: 'Sign Up',
				link: 'https://www.sharkallies.com/nofinfl-support-sign-on',
			},
			{
				label: 'Donate',
				link: 'https://www.sharkallies.com/donate',
			},
			// {
			// 	label: 'Share',
			// 	link: '#',
			// 	class: 'outline',
			// },
		],
	}),
}
</script>

<style scoped lang="scss">
.site-header {
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	position: fixed;
	z-index: 20;

	&__logo {
		max-width: 95px;
	}

	&__nav {
		align-self: flex-end;
		align-items: center;
		color: color_('primary');
		display: flex;
		flex-direction: row;
		font-family: $font-family-secondary;
		justify-content: flex-end;

		&-item {
			@include font-size('h6');
			text-transform: uppercase;

			&.outline {
				border: 3px solid color_('primary');
			}

			@include media-query('phone') {
				@include font-size('caption');
			}
		}

		a {
			@include gutter('padding-top', 0.3);
			@include gutter('padding-bottom', 0.3);
			@include gutter('padding-left', 0.8);
			@include gutter('padding-right', 0.8);
			color: color_('primary');
			display: inline-block;
			text-decoration: none;
		}
	}
}
</style>
