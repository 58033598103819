<template>
	<div class="share-links">
		<a
			class="share-links__link"
			:href="getLink(link.url)"
			v-for="(link, key) in links"
			:key="key"
			@click.prevent="goLink"
		>
			<img :src="link.icon" :alt="link.name">
		</a>
	</div>
</template>

<script>
export default {
	name: 'shareLinks',
	data: () => ({
		links: [
			{
				name: 'Facebook',
				icon: require('@/assets/icon__facebook.svg'),
				url: 'https://www.facebook.com/sharer/sharer.php?u=ADDR&t=TITLE',
			},
			{
				name: 'Twitter',
				icon: require('@/assets/icon__twitter.svg'),
				url: 'https://twitter.com/share?url=ADDR&text=TITLE',
			},
			{
				name: 'Linked In',
				icon: require('@/assets/icon__linkedin.svg'),
				url: 'https://www.linkedin.com/shareArticle?mini=true&url=ADDR&title=TITLE&source=LinkedIn',
			},
		],
	}),
	methods: {
		getLink(link) {
			const addr = encodeURIComponent(window.location.href)
			const title = encodeURIComponent('Florida leads the nation in shark fin trading. But together we can stop it. Let’s get this shady business out of the Sunshine State! #NoFinFL')
			return link.replace('ADDR', addr).replace('TITLE', title)
		},
		goLink(evt) {
			window.open(evt.currentTarget.href, '', 'menubar=no,toolbar=no,resizable=nyes,scrollbars=yes,width=600,height=450')
		},
	},
}
</script>

<style scoped lang="scss">
.share-links {
	align-items: center;
	display: flex;
	justify-content: space-between;

	&__link {
		// @include gutter('margin', 0.5);
		height: 42px;
		width: 42px;
	}
}
</style>
