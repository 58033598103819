<template>
	<div class="sticker" :style="inlineStyles">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'sticker',
	props: {
		rotate: {
			type: Number,
			default: 0,
		},
		position: {
			type: Object,
			default: () => ({
			}),
		},
	},
	computed: {
		inlineStyles() {
			const styles = Object.assign({
				transform: `rotate(${this.rotate}deg)`,
			}, this.position)
			return styles
		},
	},
}
</script>

<style scoped lang="scss">
.sticker {
	@include font-size('h5');
	@include gutter('padding', 0.5);
	align-items: center;
	background-image: url('../assets/bg__sticker@2x.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	flex-direction: row;
	font-family: $font-family-secondary;
	justify-content: center;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	position: absolute;
	height: 172px;
	width: 172px;

	@include media-query('phone') {
		display: none;
	}
}
</style>
