import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

Vue.config.productionTip = false

import notice from './lib/notice'
Vue.use(notice)

new Vue({
  render: h => h(App),
}).$mount('#app')
