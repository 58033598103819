<template>
	<div class="help">
		<div class="grid">
			<div class="grid__inner">
				<div class="grid__cell--span-8-desktop grid__cell--offset-3-desktop grid__cell--span-4-tablet grid__cell--offset-3-tablet grid__cell--span-4-phone">
					<h3 class="help__header">How Can I Help?</h3>
				</div>
				<div class="grid__inner grid__cell--span-12">
				<a href="https://www.sharkallies.com/nofinfl-support-sign-on" class="grid__cell--span-3-desktop grid__cell--span-4-tablet grid__cell--offset-3-tablet grid__cell--span-4-phone help__box">
					<div class="help__box-link">Sign Up</div>
					<p>for updates &amp; ways to participate in upcoming events / votes</p>
				</a>
				<a href="#" @click.prevent="bus.$emit('modalOpen', true)" class="grid__cell--span-3-desktop grid__cell--span-4-tablet grid__cell--offset-3-tablet grid__cell--span-4-phone help__box">
					<div class="help__box-link">Share This Site</div>
					<p>on your social channels and use #NoFinFL to help build awareness</p>
				</a>
				<a href="https://represent.com/store/nofinfl" class="grid__cell--span-3-desktop grid__cell--span-4-tablet grid__cell--offset-3-tablet grid__cell--span-4-phone help__box">
					<div class="help__box-link">Shop Our Store</div>
					<p>for updates &amp; ways to participate in upcoming events / votes</p>
				</a>

				<a href="https://www.sharkallies.com/ending-the-trade-of-shark-fins" class="grid__cell--span-3-desktop grid__cell--span-4-tablet grid__cell--offset-3-tablet grid__cell--span-4-phone help__box">
					<div class="help__box-link">Learn More</div>
					<p>about this initiative &amp; shark fin trade</p>
				</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Bus } from '@/lib/bus'

export default {
	name: 'help',
	data: () => ({
		bus: Bus,
	}),
	methods: {
		share(network) {
			let url = ''
			const addr = encodeURIComponent(window.location.href)
			const title = document.title
			switch(network) {
				case 'facebook':
					url = `https://www.facebook.com/sharer/sharer.php?u=${addr}&t=${title}`
					break
				case 'twitter':
					url = `https://twitter.com/share?url=${addr}&text=${title}`
					break
			}

			window.open(url, '', 'menubar=no,toolbar=no,resizable=nyes,scrollbars=yes,width=600,height=300')

		},
	},
}
</script>

<style scoped lang="scss">
.help {
	@include gutter('padding-bottom', 3);
	@include gutter('margin-top', 2);
	// @include gutter('padding-bottom', 2);
	background-color: color_('text');
	color: color_('primary');

	&__header {
		@include font-size('h1');
		@include gutter('padding-bottom');
		@include gutter('margin-bottom');
		position: relative;
		text-align: center;
		text-transform: uppercase;

		&:after {
			background-image: url('../assets/dashed-lines--horizontal-dark@2x.png');
			background-repeat: repeat-x;
			background-size: contain;
			content: ' ';
			display: block;
			height: 7px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
	}

	&__box {
		border: 3px solid color_('text', 'dark');

		&-link {
			@include gutter('padding', 0.5);
			@include font-size('h4');
			border-bottom: 3px solid color_('text', 'dark');
			color: color_('primary');
			display: block;
			font-family: $font-family-secondary;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: all 200ms $ease-standard;

			&:hover {
				color: color_('text');
				background-color: color_('primary');
			}
		}

		p {
			@include gutter('padding', 0.5);
			@include gutter('padding-left', 0.25);
			@include gutter('padding-right', 0.25);
			@include font-size('h6');
			color: color_('text', 'dark');
			letter-spacing: 0.25px;
			text-align: center;
		}
	}

	&__icons {
		@include gutter('padding-bottom');
		@include gutter('padding-top', 2);
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		img {
			height: 40px;
			width: 40px;
		}
	}
}
</style>
