<template>
	<footer class="site-footer">
		<a href="https://www.sharkallies.com/ending-the-trade-of-shark-fins" class="site-footer__logo-wrapper">
			<span>LEARN MORE</span><br>
			<img src="@/assets/logo__shark-allies--light.svg" class="site-footer__logo">
		</a>
		<p>Shark Allies is nonprofit organization dedicated to the protection of sharks and rays and the oceans in which they live.</p>
		<div class="grid">
			<div class="grid__inner">
				<div class="grid__cell--span-2-desktop grid__cell--offset-6-desktop grid__cell--span-2-tablet grid__cell--offset-4-tablet grid__cell--span-2-phone grid__cell--offset-2-phone site-footer__icons">
					<a
						class="social-link"
						href="https://www.facebook.com/SharkAllies/"
						target="_blank"
						rel="noopener"
					>
						<img src="@/assets/icon__facebook--alt.svg" alt="Facebook">
					</a>
					<a
						href="https://www.instagram.com/sharkallies/"
						target="_blank"
						rel="noopener"
					>
						<img src="@/assets/icon__instagram--alt.svg" alt="Instagram">
					</a>
					<a
						href="https://twitter.com/SharkAllies"
						target="_blank"
						rel="noopener"
					>
						<img src="@/assets/icon__twitter--alt.svg" alt="Twitter">
					</a>
				</div>
				<div class="grid__cell--span-12 site-footer__disclaimer">
					Concept &amp; Campaign by <a href="https://spark.us/">SPARK</a>, Development by <a href="https://5inline.com">5 Inline</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'siteFooter',
}
</script>

<style scoped lang="scss">
.site-footer {
	@include gutter('padding-bottom');
	@include gutter('padding-top');
	background-color: color_('primary');
	text-align: center;

	&__logo {
		width: 100%;

		&-wrapper {
			color: color_('text','base');
			display: inline-block;
			text-align: center;

			img {
				max-width: 120px;
			}

			span {
				@include font-size('h4');
				// @include font-weight('medium');
				display: inline-block;
				font-family: $font-family-secondary;
				margin-bottom: 0.5rem;
				margin-left: 4px;
			}
		}
	}

	&__icons {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		img {
			height: 40px;
			width: 40px;
		}
	}

	&__disclaimer {
		@include font-size('caption');

		a {
			color: color_('text','base');
			font-weight: bold;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	#Layer_1-2 {
		.cls-1 {
			fill: #F9EFE3;
		}
	}
}
</style>
